import { LoaderFunctionArgs } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { protectedLoader } from "@/pages/protected/loading";
import { MentorAPI, useAuthStore, UserAPI } from "@/services";
import { captureNetworkError } from "@/services/error-handler";
import { MentorType } from "@/types";

export const getServicesQuery = () => ({
  queryKey: ["services"],
  queryFn: MentorAPI.getServices,
});

export const getCalendlyEventsQuery = () => ({
  queryKey: ["calendly-events"],
  queryFn: UserAPI.getMyCalendlyEvents,
});

export const servicesLoader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    const mentor = (await useAuthStore.getState()?.user) as MentorType;

    try {
      await protectedLoader(args);

      if (mentor?.verified_calendly && mentor?.verified_stripe) {
        const servicesQuery = getServicesQuery();
        queryClient.getQueryData(servicesQuery.queryKey) ??
          (await queryClient.fetchQuery(servicesQuery));

        const calendlyEventsQuery = getServicesQuery();
        queryClient.getQueryData(calendlyEventsQuery.queryKey) ??
          (await queryClient.fetchQuery(calendlyEventsQuery));
      }

      return null;
    } catch (error) {
      captureNetworkError(error);
    }
  };
