import { MutableRefObject } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { LoadingState } from "@/components";
import { FormSelect } from "@/components/ui/form-select";
import { DASHBOARD_ROUTES } from "@/constants/routes";
import { MentorAPI, successToast, useAuthStore, UserAPI } from "@/services";
import { MentorType, ServiceType } from "@/types";

import { getCalendlyEventsQuery } from "./loading";

export function ModifyServiceModal({
  initialRef,
  finalRef,
  selectedService,
  isOpen,
  onClose,
  ...props
}: {
  initialRef: MutableRefObject<HTMLInputElement | null>;
  finalRef?: MutableRefObject<HTMLButtonElement | null>;
  selectedService?: ServiceType;
} & Omit<ModalProps, "children">) {
  const queryClient = useQueryClient();

  const { verified_calendly } = useAuthStore(
    (state) => state.user,
  ) as MentorType;

  const {
    data: initialData,
    isFetching,
    refetch,
  } = useQuery<UserAPI.GetMyCalendlyEventsResponse>({
    ...getCalendlyEventsQuery(),
    enabled: verified_calendly,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<MentorAPI.CreateServicePayload>(
    selectedService
      ? {
          defaultValues: selectedService,
        }
      : {
          defaultValues: {
            type: "session",
          },
        },
  );

  const handleSubmitService = handleSubmit(
    async ({
      title,
      description,
      qualifications,
      type,
      price,
      calendly_event,
    }) => {
      try {
        const res = selectedService
          ? await MentorAPI.editService(selectedService.id, {
              title,
              description,
              qualifications,
              calendly_event,
            })
          : await MentorAPI.createService({
              title,
              description,
              qualifications,
              type,
              price: +price,
              calendly_event,
            });

        if (res.status === 200) {
          successToast({
            title: `Your service ${
              selectedService ? "updated" : "created"
            } successfully!`,
          });
          onClose();

          await queryClient.invalidateQueries({
            queryKey: ["services"],
          });
        }
      } catch (error) {
        console.log({ error });
      }
    },
  );

  const { ref: titleInputRef, ...titleInputRegister } = register("title", {
    required: "This field is required",
  });

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      // isCentered
      size={{ base: "full", sm: "md" }}
      {...props}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmitService} noValidate>
        <ModalHeader>
          {selectedService ? "Edit service" : "Create new service"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          <FormControl isRequired id="title" isInvalid={Boolean(errors.title)}>
            <FormLabel htmlFor="title" fontWeight="normal">
              Title
            </FormLabel>
            <Input
              placeholder="Resume Review for Data Scientists"
              isDisabled={isSubmitting}
              {...titleInputRegister}
              ref={(e) => {
                titleInputRef(e);

                // it need to support focus on first input
                if (e) {
                  initialRef.current = e;
                }
              }}
            />
            {errors.title && (
              <FormErrorMessage>{errors.title.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            id="description"
            isInvalid={Boolean(errors.description)}
          >
            <FormLabel htmlFor="description" fontWeight="normal">
              Description
            </FormLabel>
            <Input
              placeholder="I will be happy to review your resume and provide recommendations to improve"
              isDisabled={isSubmitting}
              {...register("description", {
                required: "This field is required",
              })}
            />
            {errors.description && (
              <FormErrorMessage>{errors.description.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            id="qualifications"
            isInvalid={Boolean(errors.qualifications)}
          >
            <FormLabel htmlFor="qualifications" fontWeight="normal">
              Qualifications
            </FormLabel>
            <Input
              placeholder="I have hired data scientists for the past 5 years.."
              isDisabled={isSubmitting}
              {...register("qualifications", {
                required: "This field is required",
              })}
            />
            {errors.qualifications && (
              <FormErrorMessage>
                {errors.qualifications.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <Flex gap={4}>
            <FormControl
              isRequired
              isReadOnly
              id="type"
              isInvalid={Boolean(errors.type)}
            >
              <FormLabel htmlFor="type" fontWeight="normal">
                Type
              </FormLabel>
              <Input
                placeholder="Single Session"
                isDisabled
                {...register("type", {
                  required: "This field is required",
                })}
              />
              {errors.type && (
                <FormErrorMessage>{errors.type.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              isRequired
              id="price"
              isInvalid={Boolean(errors.price)}
              isReadOnly={Boolean(selectedService)}
            >
              <FormLabel htmlFor="price" fontWeight="normal">
                Price
              </FormLabel>
              <Input
                placeholder="USD"
                isDisabled={Boolean(selectedService) || isSubmitting}
                {...register("price", {
                  required: "This field is required",
                })}
              />
              {errors.price && (
                <FormErrorMessage>{errors.price.message}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>
          {isFetching ? (
            <LoadingState />
          ) : initialData?.collection?.length ? (
            <FormSelect
              isRequired
              label="Select the related calendly event"
              isInvalid={Boolean(errors.calendly_event)}
              helperText={errors.calendly_event?.message}
              {...register("calendly_event")}
            >
              {initialData?.collection.map((option) => (
                <option key={option.slug} value={option.uri}>
                  {option.name}
                </option>
              ))}
            </FormSelect>
          ) : (
            <Box as={Link} to={DASHBOARD_ROUTES.ACCOUNT} w="full">
              <Alert>You need to connect your calendly first!</Alert>
              You have events but not listed?{" "}
              <Button onClick={() => refetch()}>Refresh</Button>
            </Box>
          )}
        </ModalBody>
        <ModalFooter gap={4}>
          <Button w="full" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting || isFetching}
            colorScheme="brand"
            w="full"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
